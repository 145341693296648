header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.eventListContainer {
    width: 70%;
    max-width: 700px;
    min-width: 350px;
    margin: auto;
}
